import React, { useState } from 'react'
import './App.css'



function App() {
  const descriptions = [
    `<ul>
      <li>Financial Service team (Consumer Lending - Traveloka PayLater, BNPL).</li>
      <li>Lead team of Software Engineers, work closely with product and business counterpart to build solutions and improving overall technical and business metrices.</li>
    </ul>`,
    `<ul>
      <li>Payment Tribe (e-Wallet integration, Finance report, Reconciliation).</li>
      <li>Lead team of Software Engineers, work closely with product and business counterpart to build solutions and improving overall technical and business metrices.</li>
    </ul>`,
    `<ul>
      <li>O2O Tribe (Mitra Bukalapak).</li>
      <li>Develop and maintain User, Payment and Grocery services.</li>
    </ul>`,
    `<ul>
      <li>Develop front-end, back-end microservices and desktop applications in several projects for Indonesian and Japanese clients.</li>
    </ul>`,
    `<ul>
      <li>
      Develop and maintain websites (PHP, CodeIgniter, Laravel, Node.js), enabling online stores (WooCommerce, Opencart), mobile applications (Ionic) and desktop applications (.NET C#).
      </li>
    </ul>`,
    `<ul>
      <li>Maintain computer network and hardware availability, software development and improve implementation (web and desktop applications).</li>
    </ul>`,
  ]
  const [jobDescription, setJobDescription] = useState(descriptions[0])
  const handleJobClick = (type) => () => {
    setJobDescription(descriptions[type])
  }

  return (
    <>
      <div>
        <header>
          {/* <h4 className="hello">Hi, my name is</h4> */}
          <h1>William Lazuardi.</h1>
          <h4><a href="https://en.wikipedia.org/wiki/Software_engineer" target="_blank" rel="noopener noreferrer">Software Engineer</a> with years of experience in several projects and industries.</h4>
        </header>
      </div>

      <div className="section aboutSection">
        <h2>About me</h2>
        <p>
        I've been building softwares, managing team of software engineers, working together with multidisciplinary teams and experienced in working with several design pattern and cloud infrastructure.
        </p>
        <p>
          Some technologies I'm familiar with:
        </p>
        <ul>
          <li>Go</li>
          <li>Ruby on Rails</li>
          <li>Laravel</li>
          <li>JavaScript</li>
          <li>Node.js</li>
          <li>MySQL</li>
          <li>Postgre</li>
          <li>Redis</li>
          <li>MongoDB</li>
          <li>Docker</li>
          <li>Kubernetes</li>
          <li>Cloud Tech. (GCP, AWS)</li>
        </ul>
      </div>

      <div className="section">
        <h2>Experiences</h2>
        <div className="verySmall">*tap on job title for description</div>
        <div className="overflow">
        <table>
          <tbody>
            <tr>
              <td className="job" onClick={handleJobClick(0)}>
                <span className="jobTitle">Engineering Manager</span><br />
                <strong><a href="https://www.crunchbase.com/organization/traveloka" target="_blank" rel="noopener noreferrer">Traveloka</a></strong><br />
                Jan 2022 - Present<br />
                Jakarta
              </td>
              <td className="jobDescription" rowSpan="6">
                <strong>Description</strong>
                <div className="jobDescriptionText" dangerouslySetInnerHTML={{ __html: jobDescription }} />
              </td>
            </tr>
            <tr>
              <td className="job" onClick={handleJobClick(1)}>
                <span className="jobTitle">Engineering Manager</span><br />
                <strong><a href="https://www.crunchbase.com/organization/bukalapak" target="_blank" rel="noopener noreferrer">Bukalapak</a></strong><br />
                Aug 2020 - Jan 2022<br />
                Jakarta
              </td>
            </tr>
            <tr>
              <td className="job" onClick={handleJobClick(2)}>
                <span className="jobTitle">Software Engineer</span><br />
                <strong><a href="https://www.crunchbase.com/organization/bukalapak" target="_blank" rel="noopener noreferrer">Bukalapak</a></strong><br />
                Jul 2018 - Aug 2020<br />
                Jakarta
              </td>
            </tr>
            <tr>
              <td className="job" onClick={handleJobClick(3)}>
                <span className="jobTitle">Software Engineer</span><br />
                <strong><a href="https://www.crunchbase.com/organization/nomura-research-institute" target="_blank" rel="noopener noreferrer">Nomura Research Institute</a></strong><br />
                Jul 2017 - Jun 2018<br />
                Jakarta
              </td>
            </tr>
            <tr>
              <td className="job" onClick={handleJobClick(4)}>
                <span className="jobTitle">Software Developer</span><br />
                <strong><a href="#">Self-employed</a></strong><br />
                Jan 2013 - Dec 2017<br />
              </td>
            </tr>
            <tr>
              <td className="job" onClick={(handleJobClick(5))}>
                <span className="jobTitle">Operations and System<br />Development Officer</span><br />
                <strong><a href="https://www.crunchbase.com/organization/binus-university" target="_blank" rel="noopener noreferrer">Bina Nusantara University</a></strong><br />
                Feb 2014 - Sep 2015<br />
                Jakarta
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        <br />
      </div>

      {/* <div className="section projectSection">
        <h2>Some projects I have been involved</h2>
        <ul>
          <li>
            <a href="https://www.youtube.com/watch?v=mT27MXx_MZQ">Mitra Peduli (Emtek Peduli Corona)</a> development for Mitra Bukalapak, groceries donation, distribution and coupon management.
          </li>
          <li>
            <a href="https://www.youtube.com/watch?v=AVzzerZExRo" target="_blank" rel="noopener noreferrer">QRIS Development</a> for Mitra Bukalapak and integration with bank partner.
          </li>
          <li>
            Migrating several Bukalapak's microservices to cloud infrastructure (GCP).
          </li>
          <li>
            Develop and design an ERP system with Nomura Research Institute team. Mainly focus on developing Operation modules such as: Purchasing, Delivery, Scheduling, Item Management, Reports and POS.
          </li>
          <li>
            PT. Mentari Books Indonesia (mymentari.com, mentarievent.com, mentaripedia.com, mentaridigital.com). I developed complete IT-solutions for this company using Laravel, Express, Magento.
          </li>
          <li>
            IPL Payment System (housing management fee). Online payment solution for residential management (RW/RT) in Cluster Sevilla BSD City. I developed a <a href="https://www.clustersevilla.com/">web application</a> (CodeIgniter), <a href="https://play.google.com/store/apps/details?id=com.clustersevilla.sevillamobileapps&hl=en">Mobile Apps (Ionic)</a> and <a href="https://api.clustersevilla.com/">API (Lumen)</a> for this residential.
          </li>
        </ul>
        <br />
      </div> */}

      <div className="section contactSection">
        <h2>Contacts</h2>
        <ul>
          <li>Mail me <a href="mailto: williamlazuardi@gmail.com">williamlazuardi@gmail.com</a></li>
          <li>Or check my <a href="https://www.linkedin.com/in/william-lazuardi" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
        </ul>

        <footer>
          -
        </footer>
      </div>


  </>
  );
}

export default App